import { Toggle } from 'tailwindcss-stimulus-components'

export default class SearchToggle extends Toggle {
  static targets = ['toggleable', 'input']
  static values = ['open']

  connect() {
    super.connect()
  }

  toggle(e) {
    super.toggle(e)
    if (this.openValue) this.inputTarget.focus()
    else this.inputTarget.blur()
  }
}
