import "@iframely/embed.js"

const iframelyOptions = {
  api_key: 'c4613a405f5ab5f6de257b',
  lazy: true,
  click_to_play: true
}

window.iframely.extendOptions(iframelyOptions)
window.iframely.load()

document.addEventListener('turbo:load', _event => {
  window.iframely.load()
})
