// https://github.com/ankane/ahoy.js/blob/master/src/index.js#L146C1-L152C2
function generateId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function track(name, properties = {}, options = {}) {
  if (Object.keys(options).length === 0 && typeof window.ahoy !== 'undefined') {
    return window.ahoy.track(name, properties)
  }

  const event = {
    name: name,
    properties: properties,
    time: new Date().getTime() / 1000.0,
    id: generateId(),
    js: true
  }
  const data = new FormData()
  data.append('visit_token', options.visit_token)
  data.append('visitor_token', options.visitor_token)
  data.append('events_json', JSON.stringify([event]))

  window.navigator.sendBeacon(`${options.prefixUrl}/ahoy/events`, data)
}
