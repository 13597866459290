import { Controller } from "@hotwired/stimulus"

let submitWithDebounceTimer

export default class extends Controller {
  static targets = [
    'form'
  ]

  static values = {
    disableOnSubmit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  }

  submit() {
    if (this.disableOnSubmitValue) {
      if (!this.disabledValue) {
        this.formTarget.requestSubmit();
        this.disabledValue = true;
      }
    } else {
      this.formTarget.requestSubmit();
    }
  }

  submitWithDebounce() {
    clearTimeout(submitWithDebounceTimer)
    submitWithDebounceTimer = setTimeout(() => this.submit(), 1000)
  }

  reset() {
    Array.from(this.formTarget.elements).forEach((field) => {
      field.value = '';
    });
  }

  clear() {
    this.reset();
    this.submit();
  }
}
