import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static values = {
    action: String
  }
  connect() {
    this.element.addEventListener('submit', this.getRecaptchaToken)
  }

  // Since reCAPTCHA token is valid only for 2 minutes, Google advised to refresh it before submitting the form
  getRecaptchaToken = async (event) => {
    event.preventDefault()
    const recaptchaInput = this.element.querySelector(`input[name="g-recaptcha-response-data[${this.actionValue}]"]`)
    if (!recaptchaInput || !window.grecaptcha) {
      this.element.submit()
      return
    }

    const siteKey = recaptchaInput.dataset.sitekey

    const newToken = await grecaptcha.execute(siteKey, { action: this.actionValue })
    recaptchaInput.value = newToken
    this.element.submit()
  }
}
