import * as Sentry from "@sentry/browser";

if (typeof window.sentryData !== 'undefined' && window.sentryData != null) {
  const replaysSessionSampleRate = (window.sentryData.environment == 'production') ? 0.5 : 1.0;

  Sentry.init({
    dsn: window.sentryData.dsn,
    release: `vendo@${window.sentryData.release}`,
    tracesSampleRate: 1.0,
    environment: window.sentryData.environment,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: replaysSessionSampleRate,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });

  Sentry.setUser(window.sentryData.user)
}

window.Sentry = Sentry;
